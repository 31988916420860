import React, { useEffect } from "react";
import { TooltipComponent } from "@syncfusion/ej2-react-popups";
import { useNavigate } from "react-router-dom";
import { AiOutlineMenu } from "react-icons/ai";
import { useStateContext } from "../../contexts/ContextProvider";
import { useSelector } from "react-redux";

// Reusable NavButton Component
const NavButton = ({ title, customFunc, icon, color, dotColor }) => (
  <TooltipComponent content={title} position="BottomCenter">
    <button
      type="button"
      onClick={customFunc}
      style={{ color }}
      className="relative p-3 text-xl rounded-full hover:bg-[#292B2D]"
    >
      {dotColor && (
        <span
          style={{ background: dotColor }}
          className="absolute inline-flex w-2 h-2 rounded-full right-2 top-2"
        />
      )}
      {icon}
    </button>
  </TooltipComponent>
);

// Profile Section Component
const ProfileSection = ({ navigate }) => {
  const { profile } = useSelector((state) => state.user);
  return(
  <TooltipComponent content="Profile" position="BottomCenter">
    <div
      className="flex items-center gap-2 p-1 rounded-lg cursor-pointer hover:bg-[#292B2D]"
      onClick={() => navigate("/mentor/profile")}
    >
      <img
        className="w-8 h-8 rounded-full"
        src={profile.picture}
        alt="user-profile"
      />
      <div>
        <p className="ml-1 text-base font-semibold text-white">{profile.name}</p>
        <p
          className="ml-1 text-sm text-gray-400 overflow-hidden whitespace-nowrap text-ellipsis w-32"
          title="Intro of Mentor | role"
        >
          {profile?.user_details?.role || profile.intro}
        </p>
      </div>
      {/* <MdKeyboardArrowDown className="text-sm text-white" /> */}
    </div>
  </TooltipComponent>
)};

// Main Header Component
const MentorvbeeHeader = () => {
  const navigate = useNavigate();
  const { activeMenu, setActiveMenu, setScreenSize, screenSize } = useStateContext();

  // Handle screen resize
  useEffect(() => {
    const handleResize = () => setScreenSize(window.innerWidth);
    window.addEventListener("resize", handleResize);

    handleResize(); // Initialize on mount
    return () => window.removeEventListener("resize", handleResize);
  }, [setScreenSize]);

  // Toggle menu based on screen size
  useEffect(() => {
    setActiveMenu(screenSize > 900);
  }, [screenSize, setActiveMenu]);

  // Toggle menu visibility
  const toggleMenu = () => setActiveMenu(!activeMenu);

  return (
    <div
      className="fixed right-0 w-full z-10 bg-[#1A1C1E] border-b border-[#37383A]"
    >
      <div
        className={`flex justify-between items-center px-8 my-2.5 w-full transition-all ${activeMenu ? "pl-80" : "pl-8"
          }`}
      >
        {/* Menu Button */}
        <NavButton
          title="Menu"
          customFunc={toggleMenu}
          color="#BDBEBE"
          icon={<AiOutlineMenu />}
        />

        {/* Profile Section */}
        <ProfileSection navigate={navigate} />
      </div>
    </div>
  );
};

export default MentorvbeeHeader;
