import React, { useState, useRef } from 'react';
import { FaTrashAlt, FaCloudUploadAlt } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Files() {
    const [files, setFiles] = useState([]); // Store uploaded files
    const [selectedFile, setSelectedFile] = useState(null); // Store selected file
    // const [showDragAndDrop, setShowDragAndDrop] = useState(false); // Toggle drag-and-drop UI

    const fileInputRef = useRef(null); // Ref for the file input field

    // Handle file selection
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            if (validateFile(file)) {
                setSelectedFile(file);
                toast.info('File ready to upload.');
            } else {
                toast.error('Only .txt and .docx files are allowed.');
                fileInputRef.current.value = ""; // Reset input field
            }
        }
    };

    // Handle file drop in drag-and-drop area
    const handleFileDrop = (event) => {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        if (file) {
            if (validateFile(file)) {
                setSelectedFile(file);
                toast.info('File ready to upload.');
            } else {
                toast.error('Only .txt and .docx files are allowed.');
            }
        }
    };

    // File validation function
    const validateFile = (file) => {
        const allowedExtensions = ['.txt', '.docx'];
        const fileExtension = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();
        return allowedExtensions.includes(fileExtension);
    };

    // Handle file upload
    const handleFileUpload = () => {
        if (selectedFile) {
            setFiles([...files, { id: files.length + 1, name: selectedFile.name }]);
            setSelectedFile(null);
            // setShowDragAndDrop(false);
            toast.success('File uploaded successfully!');
            fileInputRef.current.value = ""; // Clear input field
        }
    };

    // Handle clearing the selected file
    const handleClearFile = () => {
        setSelectedFile(null);
        fileInputRef.current.value = ""; // Reset input field
        toast.info('File selection cleared.');
    };

    // Handle file deletion
    const handleDelete = (id) => {
        setFiles(files.filter((file) => file.id !== id));
        toast.success('File deleted successfully!');
    };

    return (
        <>
            <ToastContainer />
            <nav className="flex mx-12 my-4 lg:mt-20 md:mt-20 max-sm:mt-20 sm:mt-20" aria-label="Breadcrumb">
                Files
            </nav>
            <div className="flex justify-end px-8 mb-4 ">
                {/* Step 1: Choose File Button */}
                {/* {!showDragAndDrop && (
                    <button
                        className="px-6 py-2 bg-[#5b52e7] text-white rounded-md hover:bg-[#5247e2] cursor-pointer"
                        onClick={() => setShowDragAndDrop(true)}
                    >
                        Choose File
                    </button>
                )} */}

                {/* Step 2: Drag-and-Drop Area */}
                <input
                    ref={fileInputRef}
                    id="file-input"
                    type="file"
                    className="hidden"
                    onChange={handleFileChange}
                />

                {/* Conditional Drag-and-Drop UI */}
                {!selectedFile && (
                    <div
                        className="w-full border-2 border-dashed border-[#5B52E7] rounded-lg p-8 text-center bg-[#201F30] cursor-pointer hover:bg-[#252438]"
                        onDrop={handleFileDrop}
                        onDragOver={(e) => e.preventDefault()}
                    >
                        <label htmlFor="file-input" className="cursor-pointer">
                            <FaCloudUploadAlt className="text-4xl text-gray-600 mx-auto mb-2" color='#5B52E7' />
                            <p className="text-white font-semibold">Drag and drop a file here, or click to select a file</p>
                            <p className="text-white text-xs mt-2">Supported Format: JPEG, PNG, PDF</p>
                        </label>
                    </div>
                )}

                {/* Step 3: Show Selected File and Actions */}
                {selectedFile && (
                    <div className="w-full mt-4 flex items-center justify-between bg-[#201F30] p-4 rounded-md border-1 border-[#5B52E7]">
                        <p className="text-white">{selectedFile.name}</p>
                        <div>
                            <button
                                onClick={handleFileUpload}
                                className="px-4 py-2 bg-[#5b52e7] text-white rounded-md hover:bg-[#5247e2] mr-2"
                            >
                                Upload File
                            </button>
                            <button
                                onClick={handleClearFile}
                                className="px-4 py-2 bg-red-500 text-white rounded-md hover:bg-red-600"
                            >
                                <FaTrashAlt className="inline-block h-5 w-5" />
                            </button>
                        </div>
                    </div>
                )}

            </div>

            {/* File Table */}
            <div className="px-2 bg-[#1A1C1E] m-5 rounded-md p-4">
                <div>
                    <table className="w-full table-auto ">
                        <thead>
                            <tr className="bg-[#2B2E31] text-white">
                                <th className="px-4 py-2 text-[#BABABB]">ID</th>
                                <th className="px-4 py-2 text-left text-[#BABABB]">File</th>
                                <th className="px-4 py-2 text-[#BABABB]">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {files.map((file) => (
                                <tr key={file.id} className="border-b border-[#303234]">
                                    <td className="px-4 py-2 text-center text-[#B0B2B3] text-sm">{file.id}</td>
                                    <td className="px-4 py-2 text-[#B0B2B3] text-sm">{file.name}</td>
                                    <td className="px-4 py-2 text-[#B0B2B3] text-center text-sm">
                                        <button
                                            className="px-2 py-1 text-red-600 rounded-md transition-colors duration-300"
                                            onClick={() => handleDelete(file.id)}
                                        >
                                            <FaTrashAlt className="inline-block h-5 w-5" />
                                        </button>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </>
    );
}

export default Files;
